import { Order, PriceOffer } from '../../services/api/order/OrderService.types'
import { PropsWithChildren, ReactNode } from 'react'
import { SidebarItem } from '../dashboard/sidebar/Sidebar'
import { Trans } from 'next-i18next'
import { buildClassesWithDefault } from '../../utils/StyleHelper'
import { useCurrentUser } from '../../auth/auth'
import { usePermissions } from '../../contexts/PermissionContext'
import Administration from '../base/icon/AdministrationIcon'
import AppBar, { AppBarProps } from '../dashboard/app-bar/AppBar'
import Button from '../base/button/Button'
import Column from '../base/grid/Column'
import Layout, { LayoutProps } from './Layout'
import Link from 'next/link'
import Loading from '../base/loading/Loading'
import MobileSidebar from '../dashboard/sidebar/MobileSidebar'
import PostInfoCardList from '../dashboard/posts/PostInfoCardList'
import WrapperContent, { WrapperType } from '../base/wrapper-content'
import useMiddleware from '../../hooks/useMiddleware'
import useTheme from '../../hooks/useTheme'

export type PrimaryTitle = 'appbar' | 'layout'
export type DashboardLayoutProps = LayoutProps & {
    dashboardTitle?: ReactNode
    appBarContent?: ReactNode
    navigationLine?: ReactNode
    appBarProps?: AppBarProps
    primaryTitle?: PrimaryTitle
    showAppBar?: boolean
    showPosts?: boolean
    sidebar?: ReactNode
    footer?: JSX.Element
    demandStatus?: Order['status']
    priceOffer?: PriceOffer[]
    type?: WrapperType
    showSidebar?: boolean
}

const sidebarItems: SidebarItem[] = [
    {
        name: <Trans i18nKey='sidebar.items.administration' />,
        icon: Administration,
        navigation: [
            {
                name: <Trans i18nKey='sidebar.items.users' />,
                href: '/app/administration/user',
                permission: 'user.user.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.customers' />,
                href: '/app/administration/taker',
                permission: 'user.customer.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.cartage' />,
                href: '/app/administration/cartage',
                permission: 'cartage.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.cartage-delay' />,
                href: '/app/administration/cartage-delay',
                permission: 'cartage-delay.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.cartages_and_customers' />,
                href: '/app/administration/cartages-and-customers',
                permission: 'cartage.list-customer'
            },
            {
                name: <Trans i18nKey='sidebar.items.role' />,
                href: '/app/administration/user-role',
                permission: 'user.user-role.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.product' />,
                href: '/app/administration/product',
                permission: 'product.product.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.product_version' />,
                href: '/app/administration/product-version',
                permission: 'product.product-version.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.translation' />,
                href: '/app/administration/translation',
                permission: 'translation.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.product_category' />,
                href: '/app/administration/product-category',
                permission: 'product.product-category.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.enum_group' />,
                href: '/app/administration/enum-group',
                permission: 'product.enum-group.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.auditing' />,
                href: '/app/administration/auditing',
                permission: 'audit-log.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.demands' />,
                href: '/app/demand',
                permission: 'order.list'
            },
            {
                name: <Trans i18nKey='sidebar.items.branches' />,
                href: '/app/administration/branch',
                permission: 'branch_address.list'
            }
        ]
    }
]

const DashboardLayout = ({
    children,
    dashboardTitle,
    appBarContent,
    navigationLine,
    appBarProps,
    showAppBar = true,
    showPosts = true,
    primaryTitle = 'appbar',
    sidebar,
    footer,
    demandStatus,
    priceOffer,
    type,
    showSidebar = true,
    ...props
}: PropsWithChildren<DashboardLayoutProps>) => {
    const { openedSidebar } = useTheme()
    const { user, isLoading, isReady } = useCurrentUser()
    const { isInitialized } = useMiddleware(true)
    const { permission } = usePermissions()

    if (!isReady || isLoading || !user) {
        return null
    }

    const filteredItems = sidebarItems
        .map(item => {
            if (permission.isSuperAdmin) {
                return item
            }
            const listPermissions = user.rolePermissions.filter(rolePermission => {
                const parts = rolePermission.split('.')
                const lastPart = parts[parts.length - 1]
                return lastPart.includes('list')
            })
            if (!item.permission) {
                const filteredNavigation = item.navigation?.filter(
                    subItem => !subItem.permission || listPermissions.includes(subItem.permission)
                )

                return filteredNavigation && filteredNavigation.length > 0
                    ? { ...item, navigation: filteredNavigation }
                    : null
            }

            return listPermissions.includes(item.permission) ? item : null
        })
        .filter(Boolean)

    const renderAppBar = () => {
        const title = primaryTitle === 'appbar' ? dashboardTitle || props.title : ' '
        if (showAppBar) {
            return (
                <div className='sticky top-0 w-full bg-white z-10'>
                    <AppBar
                        title={title}
                        demandStatus={demandStatus}
                        priceOffer={priceOffer}
                        isNavBar={!!navigationLine}
                        {...appBarProps}
                    >
                        {appBarContent}
                    </AppBar>
                    {navigationLine}
                </div>
            )
        }
        return null
    }

    const renderContent = () => {
        return (
            <>
                <main>
                    <Column removePaddingX removePaddingY>
                        {renderAppBar()}
                        <div className='flex px-8 py-8 bg-cover bg-center'>
                            <div className={`${showPosts ? 'basis-3/4' : 'w-full'}`}>
                                <WrapperContent type={type}>{children}</WrapperContent>
                            </div>
                            {showPosts && (
                                <div className='basis-1/4'>
                                    <aside className='sticky top-0 h-[100vh] overflow-y-auto no-scrollbar'>
                                        <PostInfoCardList />
                                        <div className='sticky bottom-12  z-20 flex justify-center py-4'>
                                            <div className='flex xl:h-[40px] xl:mb-[-15px] xl:w-full xl:blur'></div>
                                            <Button
                                                as={Link}
                                                href='/app/administration/news'
                                                variant='destructive'
                                                className='xl:fixed xl:bottom-4'
                                            >
                                                <Trans i18nKey='dashboard.demand.table.actions.show_all' />
                                            </Button>
                                        </div>
                                    </aside>
                                </div>
                            )}
                        </div>
                    </Column>
                </main>
            </>
        )
    }

    const renderLoading = () => {
        if (!isInitialized) {
            return (
                <div className='w-full min-h-screen flex justify-center items-center'>
                    <Loading className='w-24 h-24' />
                </div>
            )
        }
    }

    return (
        <Layout {...props}>
            {isInitialized && (
                <div>
                    {showSidebar && <MobileSidebar items={filteredItems}>{sidebar}</MobileSidebar>}
                    <div
                        className={buildClassesWithDefault(
                            {
                                'md:pl-[256px]': openedSidebar && showSidebar
                            },
                            'flex flex-col justify-between min-h-screen transition-all duration-300'
                        )}
                    >
                        {renderContent()}
                        {footer}
                    </div>
                </div>
            )}
            {renderLoading()}
        </Layout>
    )
}
export default DashboardLayout
